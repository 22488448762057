import { render, staticRenderFns } from "./List.vue?vue&type=template&id=25f8f9f8&scoped=true"
import script from "./List.vue?vue&type=script&lang=js"
export * from "./List.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25f8f9f8",
  null
  
)

export default component.exports